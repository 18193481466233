// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
   apiKey: "AIzaSyB30X8UXeZ13qF2pDqPGTNt7CBIKIf-olw",
   authDomain: "op-sim.firebaseapp.com",
   projectId: "op-sim",
   storageBucket: "op-sim.appspot.com",
   messagingSenderId: "282167243282",
   appId: "1:282167243282:web:a631ec643fc5cfa86f1ecb",
   measurementId: "G-82M18QSGRM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const opChainStrg = getStorage(app);

export { opChainStrg };
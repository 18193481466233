import React, { useState, useEffect, useRef } from 'react';
import {
   findCallIV, findPutIV,
   calcCallDelta, calcPutDelta, calcGamma, calcCallTheta, calcPutTheta, calcVega
} from './func';
import SimGraph from './SimGraph';
import { OPprem, OPIV, OPresult, OPDelta, OPGamma, OPTheta, OPVega, graphTmp } from "./graphOptions";

function SimForm(props) {
   //opdata
   const [dataIndex, setDataIndex] = useState(props.num - 1);
   const [premiumData, setPremiumData] = useState("")
   const [realData, setRealData] = useState("")
   const [IVData, setIVData] = useState("")
   const [deltaData, setDeltaData] = useState("")
   const [gammaData, setGammaData] = useState("")
   const [thetaData, setThetaData] = useState("")
   const [vegaData, setVegaData] = useState("")
   const [rate, setRate] = useState(1);
   const [ulPrice, setUlPrice] = useState("");
   const [today, setToday] = useState("");
   const [time, setTime] = useState("");
   const [ltd, setLtd] = useState("")
   //postions
   const [positions, setPositions] = useState([]);

   //whenever OPcategory changes, change op data
   useEffect(() => {
      setPositions([]);
      const tmpData = props.ops[dataIndex];
      const price = tmpData.Price; const today = tmpData.today; const ltd = tmpData.LastDay; const time = tmpData.time
      setUlPrice(price); setToday(today); setLtd(ltd); setTime(time);
      const labels = tmpData.premium.map((obj) => obj.Strike);//strike for graph labels
      const premData = tmpData.premium; //premium data for each strike

      //Premium
      let premiumGraph = JSON.parse(JSON.stringify(graphTmp)); //deep copy for array
      premiumGraph.labels = labels;
      const dataCall = premData.map((obj) => (obj.CallValue === "" ? null : obj.CallValue)); //call value for graph y data
      premiumGraph.datasets[0].data = dataCall;
      const dataPut = premData.map((obj) => (obj.PutValue === "" ? null : obj.PutValue)); //put value for graph y data
      premiumGraph.datasets[1].data = dataPut;
      setPremiumData(premiumGraph);
      //passData to child
      premData[0].Price = price;
      premData[0].today = today;
      premData[0].LastDay = ltd;
      premData[0].rate = rate;
      premData[0].time = time;
      setRealData(premData);
      //IV
      let IVGraph = JSON.parse(JSON.stringify(graphTmp));//deep copy for array
      IVGraph.labels = labels;
      const IVCall = premData.map((obj) => (findCallIV(obj.CallValue, price, obj.Strike, today, ltd, rate, time)))
      IVGraph.datasets[0].data = IVCall;
      const IVPut = premData.map((obj) => (findPutIV(obj.PutValue, price, obj.Strike, today, ltd, rate, time)))
      IVGraph.datasets[1].data = IVPut;
      setIVData(IVGraph)
      //Delta-Graph
      let deltaGraph = JSON.parse(JSON.stringify(graphTmp));
      deltaGraph.labels = labels;
      const deltaCall = premData.map((obj, i) => (calcCallDelta(price, obj.Strike, today, ltd, IVGraph.datasets[0].data[i], rate, time)))
      deltaGraph.datasets[0].data = deltaCall;
      const deltaPut = premData.map((obj, i) => (calcPutDelta(price, obj.Strike, today, ltd, IVGraph.datasets[1].data[i], rate, time)))
      deltaGraph.datasets[1].data = deltaPut;
      setDeltaData(deltaGraph);
      //Gamma
      let gammaGraph = JSON.parse(JSON.stringify(graphTmp));
      gammaGraph.labels = labels;
      const gammaCall = premData.map((obj, i) => (calcGamma(price, obj.Strike, today, ltd, IVGraph.datasets[0].data[i], rate, time)))
      gammaGraph.datasets[0].data = gammaCall;
      const gammaPut = premData.map((obj, i) => (calcGamma(price, obj.Strike, today, ltd, IVGraph.datasets[1].data[i], rate, time)))
      gammaGraph.datasets[1].data = gammaPut;
      setGammaData(gammaGraph);
      //Theta
      let thetaGraph = JSON.parse(JSON.stringify(graphTmp));
      thetaGraph.labels = labels;
      const thetaCall = premData.map((obj, i) => (calcCallTheta(price, obj.Strike, today, ltd, IVGraph.datasets[0].data[i], rate, time)))
      thetaGraph.datasets[0].data = thetaCall;
      const thetaPut = premData.map((obj, i) => (calcPutTheta(price, obj.Strike, today, ltd, IVGraph.datasets[1].data[i], rate, time)))
      thetaGraph.datasets[1].data = thetaPut;
      setThetaData(thetaGraph);
      //Vega
      let vegaGraph = JSON.parse(JSON.stringify(graphTmp));
      vegaGraph.labels = labels;
      const vegaCall = premData.map((obj, i) => (calcVega(price, obj.Strike, today, ltd, IVGraph.datasets[0].data[i], rate, time)))
      vegaGraph.datasets[0].data = vegaCall;
      const vegaPut = premData.map((obj, i) => (calcVega(price, obj.Strike, today, ltd, IVGraph.datasets[1].data[i], rate, time)))
      vegaGraph.datasets[1].data = vegaPut;
      setVegaData(vegaGraph);
   }, [dataIndex]);

   //add positions
   function addPositon(e, i, ForO, CorP, LorS) {
      e.preventDefault();
      let strk = premiumData.labels[i];
      let lot;
      let entryPrice;
      let lastDay;
      let delta;
      //option
      lastDay = ltd;
      if (ForO == "O") {
         if (CorP == "C") {
            lot = document.getElementById(`C${i}${props.num}`).value; //call volume for position entry
            entryPrice = premiumData.datasets[0].data[i];
            delta = deltaData.datasets[0].data[i] * lot * 0.01;//mini
         } else if (CorP == "P") {
            lot = document.getElementById(`P${i}${props.num}`).value; //put volume for position entry
            entryPrice = premiumData.datasets[1].data[i];
            delta = deltaData.datasets[1].data[i] * lot * 0.01;
         }
         delta = Math.round(delta * 100) / 100

      }
      //future
      if (ForO == "F") {
         lastDay = "";
         entryPrice = document.getElementById(`F${props.num}_price`).value;
         lot = document.getElementById(`F${props.num}_lot`).value;
         delta = 0.01 * lot; //micro
      }
      if (lot <= 0) {
         alert("lot <= 0.");
         return;
      }
      //minus delta when selling
      if (LorS == "S") { delta = delta * -1 }
      //set to state
      setPositions([...positions, {
         ForO, strk, CorP, lastDay, LorS, lot, entryPrice, delta
      }])
   }
   //delete position
   const deleteFn = (index) => {
      const newArray = [...positions];
      newArray.splice(index, 1)
      setPositions(newArray);
   }

   //op-chain to html table
   const opTable = () => {
      let tbcontent = []
      //sort strike from high to low
      for (let i = premiumData.labels.length - 1; i >= 0; i--) {
         tbcontent.push(
            <tr key={i}>
               <td className='call-cell greek-cell'>{Math.round(vegaData.datasets[0].data[i] * 100) / 100}</td>
               <td className='call-cell greek-cell'>{Math.round(thetaData.datasets[0].data[i] * 100) / 100}</td>
               <td className='call-cell greek-cell'>{Math.round(gammaData.datasets[0].data[i] * 100000) / 100000}</td>
               <td className='call-cell greek-cell'>{Math.round(deltaData.datasets[0].data[i] * 100) / 100}</td>
               <td className='call-cell'>
                  <div className="entry-cell">
                     <div className='entry-button'>
                        <button className='buy-button' onClick={(e) => addPositon(e, i, "O", "C", "L")}>Buy</button>
                        <button className='sell-button' onClick={(e) => addPositon(e, i, "O", "C", "S")}>Sell</button>
                     </div>
                     <div>
                        <input id={`C${i}${props.num}`} type="number" step="100" min="0"></input>
                     </div>
                  </div>
               </td>
               <td className='call-cell'>{IVData.datasets[0].data[i]}</td>
               <td className='call-cell prem-cell'>{Math.round(premiumData.datasets[0].data[i])}</td>
               <td className='strike-cell'>{premiumData.labels[i]}</td>
               <td className='put-cell prem-cell'>{Math.round(premiumData.datasets[1].data[i])}</td>
               <td className='put-cell'>{IVData.datasets[1].data[i]}</td>
               <td className='put-cell'>
                  <div className="entry-cell">
                     <div>
                        <input id={`P${i}${props.num}`} type="number" step="100" min="0"></input>
                     </div>
                     <div className='entry-button'>
                        <button className='buy-button' onClick={(e) => addPositon(e, i, "O", "P", "L")}>Buy</button>
                        <button className='sell-button' onClick={(e) => addPositon(e, i, "O", "P", "S")}>Sell</button>
                     </div>
                  </div>
               </td>
               <td className='put-cell greek-cell'>{Math.round(deltaData.datasets[1].data[i] * 100) / 100}</td>
               <td className='put-cell greek-cell'>{Math.round(gammaData.datasets[1].data[i] * 100000) / 100000}</td>
               <td className='put-cell greek-cell'>{Math.round(thetaData.datasets[1].data[i] * 100) / 100}</td>
               <td className='put-cell greek-cell'>{Math.round(vegaData.datasets[1].data[i] * 100) / 100}</td>
            </tr >
         )
      }
      return tbcontent;
   }

   return (
      <div className='form'>
         <div className='h0'>--- Simulation Panel #{props.num} ---</div>
         <div className='h1'>
            <div className='caption'>Option</div>
            <label> select: </label>
            <select defaultValue={props.num - 1} onChange={(e) => setDataIndex(e.target.value)}>{
               props.ops.map((op, index) => <option key={index} value={index}>{op.Category}</option>)
            }
            </select>
         </div>

         {/* option chain */}
         <div className='table-wrapper'>
            <div className="table-scroll-header-fix">
               <table >
                  <thead>
                     <tr>
                        <th colSpan="7">CALL</th><th rowSpan="2">Strike</th><th colSpan="7">PUT</th>
                     </tr>
                     <tr>
                        <th>Vega</th><th>Theta</th><th>Gamma</th><th>Delta</th><th>entry</th><th>IV</th><th>Premium</th>
                        <th>Premium</th><th>IV</th><th>entry</th><th>Delta</th><th>Gamma</th><th>Theta</th><th>Vega</th>
                     </tr>
                  </thead>
                  <tbody>
                     {premiumData && opTable()}
                  </tbody>
               </table>
            </div>
         </div>
         {/* add future position */}
         <div className='wrapper'>
            <div className='h1 future-wrapper'>
               <div className='caption'>Future</div>
               <div className='future-input-form'>
                  <div>
                     <label> price: </label>
                     <input id={`F${props.num}_price`} defaultValue={ulPrice} type="number" step="10" min="0" />
                  </div>
                  <div>
                     <label>lot: </label>
                     <input id={`F${props.num}_lot`} type="number" step="10" min="0" />
                  </div>
                  <div className='entry-button'>
                     <button className='buy-button ' onClick={(e) => addPositon(e, null, "F", null, "L")}>Buy</button>
                     <button className='sell-button ' onClick={(e) => addPositon(e, null, "F", null, "S")}>Sell</button>
                  </div>
               </div>
            </div>
            {/* position list */}
            <div className='posilist-wrapper'>
               <div className='h2'>Position List</div>
               <table className='kodai-table'>
                  <thead>
                     <tr>
                        <th>F/O</th><th>Strike</th><th>Call/Put</th><th>Last Day</th><th>Long/Short</th><th>Lot</th><th>Entry Price</th><th>Delta</th>
                     </tr>
                  </thead>

                  {positions.length > 0 ? <>
                     <tbody>
                        {positions.map((position, index) => (
                           <tr key={index}>
                              {Object.values(position).map((val, i) => {
                                 if (i == 3)
                                    return <td> {val.slice(-5)}</td>
                                 else
                                    return <td> {val}</td>
                              })}
                              <button onClick={() => deleteFn(index)}>-</button>
                           </tr>
                        ))
                        }
                     </tbody>
                  </> :
                     <>
                        {/* <div>No Position...</div> */}
                     </>}
               </table>
            </div>
         </div>

         {/* eval position */}
         {
            realData ? <>
               <SimGraph passedData={{ positions, realData, IVData, }} num={props.num} />
            </> : <></>
         }
      </div >
   )
}

export default SimForm;
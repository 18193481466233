//for App.js to use every grandchild's(SimGraph) data to calculate calender spred
import { createContext, useReducer } from "react";
export const EvalContext = createContext();


export const EvalReducer = (state, action) => {
   const newState = state;
   switch (action.type) {
      case 'SET_EVAL':
         newState[action.num] = action.PosiGraph;
         return newState;
      default:
         return;
   }
}

const EvalContextProvider = ({ children }) => {
   const [evalState, evalDispatch] = useReducer(EvalReducer, ["a", "b"]);

   return (
      <EvalContext.Provider value={{ evalState, evalDispatch }}>
         {
            children
         }
      </EvalContext.Provider>
   )
}

export default EvalContextProvider;
//Reference
//https://www.macroption.com/black-scholes-formula/#delta

const q = 0;//suppoce divident is 0 because we use futures for our underlying
const T = 365.25
const iATM = 24; //25th of the data
const strikeInterval = 125;
const endTime = "33:00" //suppose value exists until SQ opening

function cdf(x) {
   //累積分布関数
   // https://www.marketechlabo.com/normal-distribution-javascript/ 
   // constants
   var p = 0.2316419;
   var b1 = 0.31938153;
   var b2 = -0.356563782;
   var b3 = 1.781477937;
   var b4 = -1.821255978;
   var b5 = 1.330274429;

   var t = 1 / (1 + p * Math.abs(x));
   var Z = Math.exp(-x * x / 2) / Math.sqrt(2 * Math.PI);
   var y = 1 - Z * ((((b5 * t + b4) * t + b3) * t + b2) * t + b1) * t;

   return (x > 0) ? y : 1 - y;
}

function calcTau(today, ltd, time) {
   let aDay = 86400000
   let days = Math.floor(
      (
         Date.parse(
            ltd.replace(/-/g, '\/')
         ) - Date.parse(
            today.replace(/-/g, '\/')
         )
      ) / aDay);
   let hours = (Number(endTime.slice(0, 2)) - Number(time.slice(0, 2))
      + (Number(endTime.slice(3, 5)) - Number(time.slice(3, 5))) / 60) / 24 //calculate last hh:mm - current hh:mm
   let tau = (days + hours) / T
   return tau;
}

function calcD1(price, strike, tau, vol, rate) {
   let d1 = (Math.log(price / strike) + ((rate / 100 + (vol / 100) ** 2 / 2) * tau)) / (vol / 100 * (tau ** 0.5))
   return d1;
}
function calcD2(price, strike, tau, vol, rate) {
   let d2 = (Math.log(price / strike) + ((rate / 100 - (vol / 100) ** 2 / 2) * tau)) / (vol / 100 * (tau ** 0.5))
   return d2;
}

function calcNdashD1(d1) {
   let NdashD1 = 1 / (2 * Math.PI) ** 0.5 * Math.exp(-0.5 * d1 ** 2)
   return NdashD1;
}

//Premium
export function calcCallPrem(price, strike, today, ltd, vol, rate, time) {
   let tau = calcTau(today, ltd, time)
   let d1 = calcD1(price, strike, tau, vol, rate)
   let d2 = calcD2(price, strike, tau, vol, rate)
   //CALL
   let Npd1 = cdf(d1)
   let Npd2 = cdf(d2)
   let premC = Math.exp(rate / 100 * -1 * tau) * (price * Npd1 - strike * Npd2)
   premC = Math.round(premC * 100) / 100 //round at 2nd decimal 

   return premC
}

export function calcPutPrem(price, strike, today, ltd, vol, rate, time) {
   let tau = calcTau(today, ltd, time)
   let d1 = calcD1(price, strike, tau, vol, rate)
   let d2 = calcD2(price, strike, tau, vol, rate)
   //PUT
   let Nnd1 = cdf(d1 * -1)
   let Nnd2 = cdf(d2 * -1)
   let premP = Math.exp(rate / 100 * -1 * tau) * (strike * Nnd2 - price * Nnd1)
   premP = Math.round(premP * 100) / 100 //round at 2nd decimal

   return premP
}
//Delta
export function calcCallDelta(price, strike, today, ltd, vol, rate, time) {
   let tau = calcTau(today, ltd, time);
   let d1 = calcD1(price, strike, tau, vol, rate)
   let Npd1 = cdf(d1);
   let deltaC = Math.exp(q * tau * -1) * Npd1;
   return deltaC;
}
export function calcPutDelta(price, strike, today, ltd, vol, rate, time) {
   let tau = calcTau(today, ltd, time);
   let d1 = calcD1(price, strike, tau, vol, rate)
   let Nnd1 = cdf(d1 * -1);
   let deltaP = -1 * Math.exp(q * tau * -1) * Nnd1;
   return deltaP;
}

//Gamma
export function calcGamma(price, strike, today, ltd, vol, rate, time) {
   let tau = calcTau(today, ltd, time);
   let d1 = calcD1(price, strike, tau, vol, rate)
   let NdashD1 = calcNdashD1(d1)
   let Gamma = Math.exp(-1 * tau * q) / (price * vol / 100 * tau ** 0.5) * NdashD1;
   return Gamma;
}

//Theta
export function calcCallTheta(price, strike, today, ltd, vol, rate, time) {
   let tau = calcTau(today, ltd, time);
   let d1 = calcD1(price, strike, tau, vol, rate)
   let d2 = calcD2(price, strike, tau, vol, rate)
   let Npd2 = cdf(d2)
   let NdashD1 = calcNdashD1(d1)
   let thetaC = ((-1 * price * vol / 100 * NdashD1 / 2 / (tau ** 0.5)) - (rate / 100 * strike * Math.exp(-1 * rate / 100 * tau) * Npd2)) / T //q = 0
   return thetaC;
}

export function calcPutTheta(price, strike, today, ltd, vol, rate, time) {
   let tau = calcTau(today, ltd, time);
   let d1 = calcD1(price, strike, tau, vol, rate)
   let d2 = calcD2(price, strike, tau, vol, rate)
   let Nnd2 = cdf(d2 * -1)
   let NdashD1 = calcNdashD1(d1)
   let thetaP = ((-1 * price * vol / 100 * NdashD1 / 2 / (tau ** 0.5)) + (rate / 100 * strike * Math.exp(-1 * rate / 100 * tau) * Nnd2)) / T //q = 0
   return thetaP;
}

//Vega
export function calcVega(price, strike, today, ltd, vol, rate, time) {
   let tau = calcTau(today, ltd, time);
   let d1 = calcD1(price, strike, tau, vol, rate)
   let NdashD1 = calcNdashD1(d1)
   let vega = price * Math.exp(-1 * q * tau) * (tau ** 0.5) * NdashD1 / 100;
   return vega;
}

//Find IV
export function findCallIV(prem, price, strike, today, ltd, rate, time) {
   let prevDiff = 99999999;
   let diff
   //search iv from 8% to 100%
   for (let i = 800; i <= 10000; i++) {
      diff = prem - calcCallPrem(price, strike, today, ltd, i / 100, rate, time);
      diff = Math.abs(diff);
      if (diff > prevDiff) {
         return (i - 1) / 100
      }
      prevDiff = diff;
   }
}

export function findPutIV(prem, price, strike, today, ltd, rate, time) {
   let prevDiff = 99999999;
   let diff
   //iv from 8% to 100%
   for (let i = 800; i <= 10000; i++) {
      diff = prem - calcPutPrem(price, strike, today, ltd, i / 100, rate, time);
      diff = Math.abs(diff);
      if (diff > prevDiff) {
         return (i - 1) / 100
      }
      prevDiff = diff;
   }
}

export function evaluatePositions(positions, data, IVData, volaChg, ulChg, rate, time) {
   let evaluatePositions = Array(data.length); //number of strike
   evaluatePositions.fill(0); //Initialize with value 0 for each strike
   let positionPrice; let positionLot;
   //Position Evaluation
   positions.forEach((position) => {
      positionPrice = position.entryPrice;
      positionLot = Number(position.lot);
      let basePrice = Number(data[0].Price) + Number(ulChg);
      if (position.ForO === 'F') {
         if (position.LorS === "L") {
            //Future Long
            for (let i = 0; i < data.length; i++) {
               evaluatePositions[i] = evaluatePositions[i] + positionLot * (strikeInterval * (i - iATM) - (positionPrice - basePrice))
            }
         } else if (position.LorS === "S") {
            //Future Short
            for (let i = 0; i < data.length; i++) {
               evaluatePositions[i] = evaluatePositions[i] + positionLot * ((positionPrice - basePrice) - (strikeInterval * (i - iATM)))
            }
         }
      } else if (position.ForO === "O" && position.CorP === "C") {
         //create Call Premiumdata
         let premData = JSON.parse(JSON.stringify(data));
         let basePrice = Number(premData[0].Price) + Number(ulChg);
         let iPosi = premData.findIndex(item => item.Strike == position.strk);
         let IV = Number(IVData.datasets[0].data[iPosi]) + Number(volaChg)
         for (let i = 0; i < premData.length; i++) {
            premData[i].CallValue = calcCallPrem(basePrice + strikeInterval * (i - iATM), position.strk, premData[0].today, premData[0].LastDay, IV, rate, time);
            premData[i].CallValue = Math.round(premData[i].CallValue)
         }
         //Evaluation
         if (position.LorS === "L") {
            //Call Option Long
            for (let i = 0; i < data.length; i++) {
               evaluatePositions[i] = evaluatePositions[i] + positionLot * (premData[i].CallValue - positionPrice)
            }
         } else if (position.LorS === "S") {
            //Call Option Short
            for (let i = 0; i < data.length; i++) {
               evaluatePositions[i] = evaluatePositions[i] + positionLot * (positionPrice - premData[i].CallValue)
            }
         }
      } else if (position.ForO === "O" && position.CorP === "P") {
         //create Put Premiumdata
         let premData = JSON.parse(JSON.stringify(data));
         let basePrice = Number(premData[0].Price) + Number(ulChg);
         let iPosi = premData.findIndex(item => item.Strike == position.strk);
         let IV = Number(IVData.datasets[1].data[iPosi]) + Number(volaChg)
         for (let i = 0; i < premData.length; i++) {
            premData[i].PutValue = calcPutPrem(basePrice + strikeInterval * (i - iATM), position.strk, premData[0].today, premData[0].LastDay, IV, rate, time);
            premData[i].PutValue = Math.round(premData[i].PutValue)
         }
         //Evaluation
         if (position.LorS === "L") {
            //Put Option Long
            for (let i = 0; i < data.length; i++) {
               evaluatePositions[i] = evaluatePositions[i] + positionLot * (premData[i].PutValue - positionPrice)
            }
         } else if (position.LorS === "S") {
            //Put Option Short
            for (let i = 0; i < data.length; i++) {
               evaluatePositions[i] = evaluatePositions[i] + positionLot * (positionPrice - premData[i].PutValue)
            }
         }
      }
   })
   return evaluatePositions;
}

export function evaluatePositionsSQ(positions, data) {
   let evaluatePositions = Array(data.length); //number of strike
   evaluatePositions.fill(0); //Initialize with value 0 for each strike
   let positionPrice; let positionLot;
   let underlyingPirce;
   //Position Evaluation
   positions.forEach((position) => {
      positionPrice = Number(position.entryPrice);
      positionLot = Number(position.lot);
      let basePrice = Number(data[0].Price);
      if (position.ForO === 'F') {
         if (position.LorS === "L") {
            //Future Long
            for (let i = 0; i < data.length; i++) {
               evaluatePositions[i] = evaluatePositions[i] + positionLot * (strikeInterval * (i - iATM) - (positionPrice - basePrice))
            }
         } else if (position.LorS === "S") {
            //Future Short
            for (let i = 0; i < data.length; i++) {
               evaluatePositions[i] = evaluatePositions[i] + positionLot * ((positionPrice - basePrice) - (strikeInterval * (i - iATM)))
            }
         }
      } else if (position.ForO === "O" && position.CorP === "C") {
         //create Call Premiumdata
         let premData = JSON.parse(JSON.stringify(data));
         let basePrice = Number(premData[0].Price);
         let iPosi = premData.findIndex(item => item.Strike == position.strk);
         let positionStrike = Number(premData[iPosi].Strike);
         //Evaluation
         if (position.LorS === "L") {
            //Call Option Long
            for (let i = 0; i < data.length; i++) {
               underlyingPirce = basePrice + strikeInterval * (i - iATM);
               if (underlyingPirce > positionStrike) {
                  evaluatePositions[i] = evaluatePositions[i] + ((underlyingPirce - positionStrike) - positionPrice) * positionLot;
               } else {
                  evaluatePositions[i] = evaluatePositions[i] - positionPrice * positionLot;
               }
            }
         } else if (position.LorS === "S") {
            //Call Option Short
            for (let i = 0; i < data.length; i++) {
               underlyingPirce = basePrice + strikeInterval * (i - iATM);
               if (underlyingPirce > positionStrike) {
                  evaluatePositions[i] = evaluatePositions[i] - ((underlyingPirce - positionStrike) - positionPrice) * positionLot;
               } else {
                  evaluatePositions[i] = evaluatePositions[i] + positionPrice * positionLot;
               }
            }
         }
      } else if (position.ForO === "O" && position.CorP === "P") {
         //create Put Premiumdata
         let premData = JSON.parse(JSON.stringify(data));
         let basePrice = Number(premData[0].Price);
         let iPosi = premData.findIndex(item => item.Strike == position.strk);
         let positionStrike = premData[iPosi].Strike;
         //Evaluation
         if (position.LorS === "L") {
            //Put Option Long
            for (let i = 0; i < data.length; i++) {
               underlyingPirce = basePrice + strikeInterval * (i - iATM);
               if (underlyingPirce < positionStrike) {
                  evaluatePositions[i] = evaluatePositions[i] + ((positionStrike - underlyingPirce) - positionPrice) * positionLot;
               } else {
                  evaluatePositions[i] = evaluatePositions[i] - positionPrice * positionLot;
               }
            }
         } else if (position.LorS === "S") {
            //Put Option Short
            for (let i = 0; i < data.length; i++) {
               underlyingPirce = basePrice + strikeInterval * (i - iATM);
               if (underlyingPirce < positionStrike) {
                  evaluatePositions[i] = evaluatePositions[i] - ((positionStrike - underlyingPirce) - positionPrice) * positionLot;
               } else {
                  evaluatePositions[i] = evaluatePositions[i] + positionPrice * positionLot;
               }
            }
         }
      }
   })
   return evaluatePositions;
}

export function evaluatePositions_freeTrade(positions, simCondition, rate) {
   const n = 49
   let evaluatePositions = new Array(n); //number of strike
   evaluatePositions.fill(0); //Initialize with value 0 for each strike
   let premData = new Array(n).fill({ CallValue: "", PutValue: "" });
   premData = JSON.parse(JSON.stringify(premData));
   let positionPrice; let positionLot;
   //Position Evaluation
   positions.forEach((position) => {
      positionPrice = position.entryPrice;
      positionLot = Number(position.lot);
      let basePrice = Number(simCondition.ulPrice);
      if (position.ForO === 'F') {
         if (position.LorS === "L") {
            //Future Long
            for (let i = 0; i < n; i++) {
               evaluatePositions[i] = evaluatePositions[i] + positionLot * (strikeInterval * (i - iATM) - (positionPrice - basePrice))
            }
         } else if (position.LorS === "S") {
            //Future Short
            for (let i = 0; i < n; i++) {
               evaluatePositions[i] = evaluatePositions[i] + positionLot * ((positionPrice - basePrice) - (strikeInterval * (i - iATM)))
            }
         }
      } else if (position.ForO === "O" && position.CorP === "C") {
         //create Call Premiumdata
         let basePrice = Number(simCondition.ulPrice)
         let IV = Number(position.IV) + Number(simCondition.IVchg)
         for (let i = 0; i < n; i++) {
            premData[i].CallValue = calcCallPrem(basePrice + strikeInterval * (i - iATM), position.strk, simCondition.today, position.lastDay, IV, rate, simCondition.time);
            premData[i].CallValue = Math.round(premData[i].CallValue)
         }
         //Evaluation
         if (position.LorS === "L") {
            //Call Option Long
            for (let i = 0; i < n; i++) {
               evaluatePositions[i] = evaluatePositions[i] + positionLot * (premData[i].CallValue - positionPrice)
            }
         } else if (position.LorS === "S") {
            //Call Option Short
            for (let i = 0; i < n; i++) {
               evaluatePositions[i] = evaluatePositions[i] + positionLot * (positionPrice - premData[i].CallValue)
            }
         }
      } else if (position.ForO === "O" && position.CorP === "P") {
         //create Put Premiumdata
         let basePrice = Number(simCondition.ulPrice)
         let IV = Number(position.IV) + Number(simCondition.IVchg)
         for (let i = 0; i < n; i++) {
            premData[i].PutValue = calcPutPrem(basePrice + strikeInterval * (i - iATM), position.strk, simCondition.today, position.lastDay, IV, rate, simCondition.time);
            premData[i].PutValue = Math.round(premData[i].PutValue)
         }
         //Evaluation
         if (position.LorS === "L") {
            //Put Option Long
            for (let i = 0; i < n; i++) {
               evaluatePositions[i] = evaluatePositions[i] + positionLot * (premData[i].PutValue - positionPrice)
            }
         } else if (position.LorS === "S") {
            //Put Option Short
            for (let i = 0; i < n; i++) {
               evaluatePositions[i] = evaluatePositions[i] + positionLot * (positionPrice - premData[i].PutValue)
            }
         }
      }
   })
   return evaluatePositions;
}




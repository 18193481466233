import React, { useState, useEffect, useContext } from "react"

import { Bar, Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { evaluatePositions, evaluatePositionsSQ } from './func';
import { OPprem, OPIV, OPresult, OPDelta, OPGamma, OPTheta, OPVega, graphData, evalTmp } from "./graphOptions";

import { EvalContext } from "./context/evaldata-context";

function SimGraph(props) {// props:data from parent component(App.js)
   //let today = new Date().toLocaleDateString('sv-SE');//sv enable "mm-dd-yyyy" format
   Chart.register(...registerables);
   const { evalDispatch } = useContext(EvalContext);
   const realData = props.passedData.realData;
   const positions = props.passedData.positions;
   const IVData = props.passedData.IVData;
   const today = realData[0].today;
   const time = realData[0].time;

   const [graphOP, setGO] = useState(OPresult);

   //state
   const [premiumData, setPremiumData] = useState("")
   const [deltaData, setDeltaData] = useState("")
   const [gammaData, setGammaData] = useState("")
   const [thetaData, setThetaData] = useState("")
   const [vegaData, setVegaData] = useState("")
   const [evalData, setEvalData] = useState("")

   const [day1, setDay1] = useState(today)
   const [volaChg1, setVolaChg1] = useState(0)
   const [UlChg1, setUlChg1] = useState(0)
   const [time1, setTime1] = useState("15:00")
   const [day2, setDay2] = useState(today)
   const [volaChg2, setVolaChg2] = useState(0)
   const [UlChg2, setUlChg2] = useState(0)
   const [time2, setTime2] = useState("15:00")
   const [day3, setDay3] = useState(today)
   const [volaChg3, setVolaChg3] = useState(0)
   const [UlChg3, setUlChg3] = useState(0)
   const [time3, setTime3] = useState("15:00")

   useEffect(() => {
      const ATM = realData[(realData.length - 1) / 2].Strike;
      const labels = realData.map((obj) => (obj.Strike - ATM));//under lying change amount for X
      let PosiGraph = JSON.parse(JSON.stringify(evalTmp));
      PosiGraph.labels = labels;
      //Todays's Evaluation
      PosiGraph.datasets[0].label = realData[0].today.slice(-5) + " (" + realData[0].time
      PosiGraph.datasets[0].data = evaluatePositions(positions, realData, IVData, 0, 0, realData[0].rate, time);

      //SQ's Evaluation
      PosiGraph.datasets[4].data = evaluatePositionsSQ(positions, realData);
      setEvalData(PosiGraph);
      evalDispatch({ type: 'SET_EVAL', num: props.num - 1, PosiGraph }) //pass to context for 1st layert(App.js) to calculate calender spread
   }, [props.passedData]);

   // useEffect(() => {
   //    setGc(evalData);//pass to GrandParent(App.js) for Calender Spread
   //    console.log("a")
   // }, [evalData]);

   const handleAddSim1 = (e) => {
      e.preventDefault();
      //create simulation data
      let simData1 = JSON.parse(JSON.stringify(realData));
      simData1[0].today = day1;
      //add simulation data to current position data
      let PosiGraph = JSON.parse(JSON.stringify(evalData));
      PosiGraph.datasets[1].label = day1.slice(-5) + " (" + time1;
      PosiGraph.datasets[1].data = evaluatePositions(positions, simData1, IVData, volaChg1, UlChg1, realData[0].rate, time1);
      setEvalData(PosiGraph);
      evalDispatch({ type: 'SET_EVAL', num: props.num - 1, PosiGraph }) //pass to context for 1st layert(App.js) to calculate calender spread
   }

   const handleAddSim2 = (e) => {
      e.preventDefault();
      //create simulation data
      let simData2 = JSON.parse(JSON.stringify(realData));
      simData2[0].today = day2;
      //add simulation data to current position data
      let PosiGraph = JSON.parse(JSON.stringify(evalData));
      PosiGraph.datasets[2].label = day2.slice(-5) + " (" + time2;
      PosiGraph.datasets[2].data = evaluatePositions(positions, simData2, IVData, volaChg2, UlChg2, realData[0].rate, time2);
      setEvalData(PosiGraph);
      evalDispatch({ type: 'SET_EVAL', num: props.num - 1, PosiGraph }) //pass to context for 1st layert(App.js) to calculate calender spread
   }

   const handleAddSim3 = (e) => {
      e.preventDefault();
      //create simulation data
      let simData3 = JSON.parse(JSON.stringify(realData));
      simData3[0].today = day3;
      //add simulation data to current position data
      let PosiGraph = JSON.parse(JSON.stringify(evalData));
      PosiGraph.datasets[3].label = day3.slice(-5) + " (" + time3;
      PosiGraph.datasets[3].data = evaluatePositions(positions, simData3, IVData, volaChg3, UlChg3, realData[0].rate, time3);
      setEvalData(PosiGraph);
      evalDispatch({ type: 'SET_EVAL', num: props.num - 1, PosiGraph }) //pass to context for 1st layert(App.js) to calculate calender spread
   }

   const handleGraphChange = (e) => {
      e.preventDefault();
      let gr = Number(e.target.value);
      setGO({
         ...graphOP,
         scales: {
            ...graphOP.scales,
            x: {
               ...graphOP.scales.x,
               min: gr * -1,
               max: gr
            }
         }
      }
      )
   }

   return (
      <div>
         {evalData ?
            <>
               <div className="h2">Simulation Parameter</div>
               <div className="simform-wrapper">
                  <form onSubmit={handleAddSim1} >
                     <div className="evalform">
                        <div className="evalform-field date">
                           <label>Date1: </label>
                           <input type="date" onChange={e => setDay1(e.target.value)} defaultValue={today} />
                        </div>
                        <div className="evalform-field date">
                           <label>Time1: </label>
                           <input type="time" onChange={e => setTime1(e.target.value)} value={time1} />
                        </div>
                        <div className="evalform-field">
                           <label>IV chg(%): </label>
                           <input type="number" onChange={e => setVolaChg1(e.target.value)} />
                        </div>
                        <div className="evalform-field">
                           <button type="submit">Apply Date1</button>
                        </div>
                     </div>
                  </form>

                  <form onSubmit={handleAddSim2}>
                     <div className="evalform">
                        <div className="evalform-field date">
                           <label>Date2: </label>
                           <input type="date" onChange={e => setDay2(e.target.value)} defaultValue={today} />
                        </div>
                        <div className="evalform-field date">
                           <label>Time2: </label>
                           <input type="time" onChange={e => setTime2(e.target.value)} value={time2} />
                        </div>
                        <div className="evalform-field">
                           <label>IV chg(%): </label>
                           <input type="number" onChange={e => setVolaChg2(e.target.value)} />
                        </div>
                        <div className="evalform-field">
                           <button type="submit">Apply Date2</button>
                        </div>
                     </div>
                  </form>

                  <form onSubmit={handleAddSim3}>
                     <div className="evalform">
                        <div className="evalform-field date">
                           <label>Date3: </label>
                           <input type="date" onChange={e => setDay3(e.target.value)} defaultValue={today} />
                        </div>
                        <div className="evalform-field date">
                           <label>Time3: </label>
                           <input type="time" onChange={e => setTime3(e.target.value)} value={time3} />
                        </div>
                        <div className="evalform-field">
                           <label>IV chg(%): </label>
                           <input type="number" onChange={e => setVolaChg3(e.target.value)} />
                        </div>
                        <div className="evalform-field">
                           <button type="submit">Apply Date3</button>
                        </div>
                     </div>
                  </form>
               </div>

               <div className='range-selector'>
                  <label>Graph Range: </label>
                  <select defaultValue={1500} onChange={(e) => handleGraphChange(e)}>
                     <option key="1" >500</option>
                     <option key="2" >1000</option>
                     <option key="3" >1500</option>
                     <option key="4" >2000</option>
                     <option key="5" >3000</option>
                  </select>
               </div>

               <div className="bar-chart">
                  <Line data={evalData} options={graphOP} />
               </div>
            </> :
            <></>
         }
      </div >
   )
}

export default SimGraph;